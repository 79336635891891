import React, { useState } from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import { Link } from "react-router-dom"; // Linkをインポート
import styles from "../style.module.css";
import { Tag } from "../types";
import {
  DEFAULT_THUMBNAIL_IMAGE,
  STATIC_FILE_ORIGIN,
} from "../utils/constants";
import { Dictionary } from "express-serve-static-core";

interface ArticleThumbnailProps {
  content: Content;
}

type Content = {
  id: string;
  title: string;
  content: string;
  eyecatch: Record<string, any>;
  createdAt: string;
  tag: Tag[];
};

const ArticleThumbnail: React.FC<ArticleThumbnailProps> = ({ content }) => {
  const [imageSrc, setImageSrc] = useState(() => {
    return content.eyecatch ? content.eyecatch.url : DEFAULT_THUMBNAIL_IMAGE;
  });

  return (
    <Link
      to={`/articles/${content.id}`}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <Card className={styles.articleCard}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // 水平方向のセンタリング
            alignItems: "center", // 垂直方向のセンタリング
            overflow: "hidden", // 画像が枠からはみ出た場合に切り取る
            p: 2, // 水平・垂直方向に余白を持たせる
            backgroundColor: "rgba(0, 175, 204, 0.5)",
          }}
        >
          {/* アイキャッチ画像 */}
          <Box
            component="img"
            src={imageSrc}
            alt={content.title}
            onError={() => setImageSrc(DEFAULT_THUMBNAIL_IMAGE)} // 画像読み込みエラー時にデフォルト画像に切り替え
            sx={{
              width: "64px",
              height: "auto",
            }}
          />
        </Box>
        {/* タイトルと公開日 */}
        <CardContent>
          <Typography
            variant="body1"
            component="div"
            sx={{
              display: "-webkit-box", // Flexboxで要素を配置
              WebkitBoxOrient: "vertical", // 縦方向の配置を指定
              overflow: "hidden", // はみ出した部分を隠す
              textOverflow: "ellipsis", // 省略記号を表示
              WebkitLineClamp: 2, // 最大行数を2行に制限
              lineHeight: "1.5", // 行の高さを指定
              maxHeight: "3em", // 2行分の高さ
            }}
            gutterBottom
          >
            {content.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {content.createdAt.slice(0, 10)}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

export default ArticleThumbnail;
