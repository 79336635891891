import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import BlogPage from "./pages/BlogPage";
import theme from "./theme";
import Header from "./components/Header";
import { Container, Box } from "@mui/material";
import ArticlePage from "./pages/ArticleDetailPage";
import styles from "./style.module.css";
import usePageTracking from "./utils/usePageTracking";

const App: React.FC = () => {
  return (
    <Container maxWidth="md" className={styles.rootContainer}>
      {/* アプリ全体にCSSを適用 */}
      <ThemeProvider theme={theme}>
        {/* リセットCSS */}
        <CssBaseline />
        <Router>
          {/* usePageTrackingはRouterの内部で呼び出す */}
          <PageTrackingWrapper>
            {/* 共通のヘッダーを追加 */}
            <Box sx={{ mt: 3 }}>
              <Header />
            </Box>
            <Routes>
              <Route path="/" element={<BlogPage />} />
              <Route path="/articles/:id" element={<ArticlePage />} />
            </Routes>
          </PageTrackingWrapper>
        </Router>
      </ThemeProvider>
    </Container>
  );
};

// JSX内でusePageTrackingを呼び出すためのラッパーコンポーネント
// JSX内でJS関数は直接呼び出せない
const PageTrackingWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  usePageTracking(); // Routerコンテキスト内で呼び出す
  return <>{children}</>;
};

export default App;
