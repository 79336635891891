import { API_GATE_WAY_BASE_DOMAIN } from "./constants";

/**
 * APIクライアントクラス
 * ベースURLと共通ヘッダーを指定して、HTTPリクエストを管理します。
 */
export default class ApiClient {
  private static protocol: string = "https";
  private static headers: HeadersInit = {
    "Content-Type": "application/json",
  };
  private static maxRetries: number = 3; // 最大リトライ回数

  /**
   * GETリクエストを送信
   * 指定されたエンドポイントにGETリクエストを送り、データを取得します。
   * @param endpoint - リクエスト先のエンドポイント
   * @param params - クエリパラメータのオブジェクト（任意）
   * @param retries - 現在のリトライ回数
   * @returns レスポンスのJSONデータ
   * @throws HTTPステータスが200以外の場合にエラーをスロー
   */
  static async get(
    endpoint: string,
    params?: Record<string, string> | null,
    retries: number = 0,
  ): Promise<any> {
    let url = `${ApiClient.protocol}://${API_GATE_WAY_BASE_DOMAIN}/${endpoint}`;
    if (params) {
      const queryString = new URLSearchParams(params).toString();
      url += `?${queryString}`;
    }

    try {
      const response: Response = await fetch(url, {
        method: "GET",
        headers: ApiClient.headers,
      });
      return this.handleResponse(response);
    } catch (error) {
      if (retries < this.maxRetries) {
        console.warn(`リトライ中 (${retries + 1}/${this.maxRetries})...`);
        return this.get(endpoint, params, retries + 1); // 再試行
      } else {
        throw new Error(`最大リトライ回数に達しました: ${error}`);
      }
    }
  }

  /**
   * POSTリクエストを送信
   * 指定されたエンドポイントにPOSTリクエストを送り、データを送信します。
   * @param endpoint - リクエスト先のエンドポイント
   * @param body - リクエストボディ（JSON形式）
   * @param retries - 現在のリトライ回数
   * @returns レスポンスのJSONデータ
   * @throws HTTPステータスが200以外の場合にエラーをスロー
   */
  static async post(
    endpoint: string,
    body: Record<string, any>,
    retries: number = 0,
  ): Promise<any> {
    let url = `${ApiClient.protocol}://${API_GATE_WAY_BASE_DOMAIN}/${endpoint}`;

    try {
      const response: Response = await fetch(url, {
        method: "POST",
        headers: ApiClient.headers,
        body: JSON.stringify(body),
      });
      return this.handleResponse(response);
    } catch (error) {
      if (retries < this.maxRetries) {
        console.warn(`リトライ中 (${retries + 1}/${this.maxRetries})...`);
        return this.post(endpoint, body, retries + 1); // 再試行
      } else {
        throw new Error(`最大リトライ回数に達しました: ${error}`);
      }
    }
  }

  /**
   * PATCHリクエストを送信
   * 指定されたエンドポイントにPATCHリクエストを送り、データを部分的に更新します。
   * @param endpoint - リクエスト先のエンドポイント
   * @param body - 更新するデータ（JSON形式）
   * @param retries - 現在のリトライ回数
   * @returns レスポンスのJSONデータ
   * @throws HTTPステータスが200以外の場合にエラーをスロー
   */
  static async patch(
    endpoint: string,
    body: Record<string, any>,
    retries: number = 0,
  ): Promise<any> {
    let url = `${ApiClient.protocol}://${API_GATE_WAY_BASE_DOMAIN}/${endpoint}`;

    try {
      const response: Response = await fetch(url, {
        method: "PATCH",
        headers: ApiClient.headers,
        body: JSON.stringify(body),
      });
      return this.handleResponse(response);
    } catch (error) {
      if (retries < this.maxRetries) {
        console.warn(`リトライ中 (${retries + 1}/${this.maxRetries})...`);
        return this.patch(endpoint, body, retries + 1); // 再試行
      } else {
        throw new Error(`最大リトライ回数に達しました: ${error}`);
      }
    }
  }

  /**
   * レスポンスのエラーハンドリング
   * レスポンスが正常でない場合にエラーをスローし、正常な場合はJSONデータを返します。
   * @param response - フェッチリクエストのレスポンスオブジェクト
   * @returns レスポンスのJSONデータ
   * @throws レスポンスが正常でない場合にエラーをスロー
   */
  private static async handleResponse(response: Response): Promise<any> {
    if (!response.ok) {
      const errorText: string = await response.text();
      throw new Error(`HTTP error! Status: ${response.status} - ${errorText}`);
    }
    return await response.json();
  }
}
