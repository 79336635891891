import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import parse from "html-react-parser";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { base16AteliersulphurpoolLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Tag } from "../types";

interface ArticleContentProps {
  content: string;
}

const ArticleContent: React.FC<ArticleContentProps> = ({ content }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [modalImageSrc, setModalImageSrc] = useState<string | null>(null);

  /**
   * 画像クリック時にモーダルウィンドウで表示
   */
  const handleImageClick = (src: string) => {
    setModalImageSrc(src);
    setOpen(true);
  };

  /**
   * 画像非アクティブ/閉じるボタン押下でモーダルウィンドウを閉じる
   */
  const handleClose = () => {
    setOpen(false);
    setModalImageSrc(null);
  };

  // HTML文字列をReact要素としてパースし、imgタグにonClickハンドラを追加
  const parsedContent = parse(content, {
    replace: (domNode: any) => {
      if (domNode.name === "img" && domNode.attribs) {
        return (
          <Box
            component="img"
            src={domNode.attribs.src}
            alt={domNode.attribs.alt || "Image"}
            sx={{
              display: "block",
              margin: "0 auto",
              maxWidth: "100%",
              height: "auto",
              objectFit: "cover",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={() => handleImageClick(domNode.attribs.src)}
          />
        );
      }

      if (
        domNode.name === "pre" &&
        domNode.children &&
        domNode.children[0].name === "code"
      ) {
        const codeNode = domNode.children[0];
        const languageClass = codeNode.attribs?.class || "";
        const language = languageClass.replace("language-", "") || "text";
        const codeContent = codeNode.children[0].data;

        return (
          <Box my={2}>
            <SyntaxHighlighter
              language={language}
              style={base16AteliersulphurpoolLight}
            >
              {codeContent}
            </SyntaxHighlighter>
          </Box>
        );
      }

      if (domNode.name === "h1") {
        return (
          // ブログタイトル以外にh1は含ませたくないのでh2に置き換える
          <Typography variant="h2" component="h2">
            {domNode.children[0].data}
          </Typography>
        );
      }
    },
  });

  return (
    <>
      <Box sx={{ marginTop: 2 }}>{parsedContent}</Box>

      {/* モーダル */}
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogContent sx={{ position: "relative", p: 0 }}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "white",
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
          {modalImageSrc && (
            <Box
              component="img"
              src={modalImageSrc}
              alt="Enlarged"
              sx={{
                width: "100%",
                height: "auto",
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ArticleContent;
