import { createTheme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";

// アプリ全体のCSSスタイルを指定
const theme = createTheme({
  palette: {
    text: {
      primary: "#505a66 !important", // フォントカラー
    },
    primary: {
      light: blue[300],
      main: blue[100],
      dark: blue[700],
    },
  },
  typography: {
    fontFamily: [
      "Roboto", // メインフォント
      "Kiwi Maru", // 日本語フォント
      "Arial", // フォールバックフォント
      "sans-serif",
    ].join(","),
    fontSize: 16,
    h1: {
      fontSize: "1.30rem",
    },
    subtitle1: {
      fontSize: "1.10rem",
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.875rem",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: "#505a66", // 全体のテキストカラー
          fontFamily: "Roboto, Kiwi Maru, Arial, sans-serif", // 全体のフォント
          fontSize: 16,
          lineHeight: 1.5, // 行間
          backgroundColor: "#ffffff", // 背景色
        },
        h2: {
          fontSize: "1.20rem",
          fontWeight: "bold",
        },
        h3: {
          fontSize: "1.15rem",
          fontWeight: "bold",
        },
        h4: {
          fontSize: "1.10rem",
          fontWeight: "bold",
        },
        h5: {
          fontSize: "1.05rem",
          fontWeight: "bold",
        },
        h6: {
          fontSize: "1.025rem",
          fontWeight: "bold",
        },
      },
    },
  },
});

export default theme;
