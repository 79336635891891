import { Box, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import styles from "../style.module.css";

interface ArticleTitleProps {
  title: string;
  date: string;
}

const ArticleTitle: React.FC<ArticleTitleProps> = ({ title, date }) => {
  return (
    <div>
      <Typography component="div" className={styles.articleTitle}>
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          mt: 0.4,
        }}
      >
        <Typography component="div" sx={{ mt: -0.5 }}>
          <CalendarMonthIcon sx={{ fontSize: "1.35rem" }} />
        </Typography>
        <Typography
          component="div"
          sx={{ mt: -1.2, ml: 0.5, mr: 0.5, fontSize: "1.0rem" }}
        >
          {date}
        </Typography>
      </Box>
    </div>
  );
};

export default ArticleTitle;
