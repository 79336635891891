import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ApiClient from "../utils/apiClient";
import { Tag, Comment } from "../types";

interface CommentFormProps {
  content: Content;
  onCommentPosted: () => void;
}

type Content = {
  id: string;
  title: string;
  content: string;
  eyecatch: Record<string, any>;
  createdAt: string;
  revisedAt: string;
  publishedAt: string;
  tag: Tag[];
  comment: Comment[];
};

const CommentForm: React.FC<CommentFormProps> = ({
  content,
  onCommentPosted,
}) => {
  // モーダルの活性・非活性を管理するステート
  const [open, setOpen] = useState(false);
  // コメントの送信状態を管理するステート
  const [submitted, setSubmitted] = useState(false);
  // 名前フォームの内容を保持するステート
  const [userName, setUserName] = useState("");
  // メッセージ本文フォームの内容を保持するステート
  const [comment, setComment] = useState("");
  // コメントのエラーメッセージを制御するステート
  const [isSendingError, setIsSendingError] = useState(false);
  // バリデーションエラーメッセージ
  const [validationError, setValidationError] = useState("");

  async function postComment(
    userName: string,
    comment: string,
  ): Promise<boolean> {
    try {
      const commentApiQuery = { userName, comment };
      const commentApiRes = await ApiClient.post("comment", commentApiQuery);

      const existingIds: string[] = content.comment.map(
        (comment) => comment.id,
      );
      const mergedIds: string[] = [...existingIds, commentApiRes.id];
      const blogApiQuery = { comment: mergedIds };
      await ApiClient.patch(`blogs/${content.id}`, blogApiQuery);

      onCommentPosted();
      setIsSendingError(false);
      return true;
    } catch (err) {
      console.log("error");
      setIsSendingError(true);
      return false;
    }
  }

  const handleOpen = () => {
    setOpen(true);
    setValidationError("");
  };

  const handleClose = () => {
    setOpen(false);
    setSubmitted(false);
    setValidationError("");
  };

  /**
   * フォームの送信を処理します。
   * - `submitted`を`true`にして、送信完了メッセージを表示します。
   * - 2秒後に`userName`と`comment`フィールドをクリアし、モーダルを閉じます。
   */
  const handleSubmit = async () => {
    // 入力チェック
    if (!userName.trim() || !comment.trim()) {
      setValidationError("名前とコメントの両方を入力してください");
      return;
    }

    const isSuccess = await postComment(userName, comment);
    console.log(`isSendingError: ${isSendingError}`);

    if (!isSuccess) {
      console.error("コメント投稿失敗");
      return;
    } else {
      setIsSendingError(false);
      setSubmitted(true);
    }

    setTimeout(() => {
      setUserName("");
      setComment("");
      handleClose();
    }, 2000);
  };

  /**
   * 名前入力に基づいて`username`ステートを更新します。
   */
  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
    setValidationError(""); // 入力中はエラーメッセージをクリア
  };

  /**
   * コメント入力に基づいて`comment`ステートを更新します。
   */
  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
    setValidationError(""); // 入力中はエラーメッセージをクリア
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        startIcon={<SendIcon />}
        sx={{ backgroundColor: "#9FD7EE", color: "#ffffff" }}
      >
        コメントを書く
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        {submitted ? (
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: "320px",
              margin: "auto",
              textAlign: "center",
              background: "url(/bg_pattern_rasen.png) repeat",
            }}
          >
            <Box
              component="img"
              src="/thank_you__i.png.webp"
              alt="Thank You"
              sx={{ width: 180, height: "auto", marginRight: 1 }}
            />
            <Box
              component="img"
              src="/blob_bongo_cat_keyboard.gif.webp"
              alt="Celebration Cat"
              sx={{ width: 50, height: "auto" }}
            />
          </DialogContent>
        ) : (
          <DialogContent
            sx={{
              padding: 4,
              border: "2px solid #bbb",
              borderRadius: 1,
              boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
              background: "url(/bg_pattern_star.png) repeat",
              minHeight: "400px",
              maxWidth: "600px",
            }}
          >
            <TextField
              fullWidth
              placeholder="お名前を入力"
              variant="outlined"
              value={userName}
              onChange={handleUserNameChange}
              sx={{ marginBottom: 3, backgroundColor: "#fff", borderRadius: 1 }}
            />
            <TextField
              fullWidth
              multiline
              minRows={10}
              placeholder="ここにメッセージを入力"
              variant="outlined"
              value={comment}
              onChange={handleCommentChange}
              sx={{ marginBottom: 3, backgroundColor: "#fff", borderRadius: 1 }}
            />
            {validationError && (
              <Typography
                color="error"
                sx={{
                  marginBottom: 2,
                  fontWeight: "bold",
                  fontSize: "1.20rem",
                }}
              >
                {validationError}
              </Typography>
            )}
            {isSendingError && (
              <Typography
                color="error"
                sx={{
                  marginBottom: 2,
                  fontWeight: "bold",
                  fontSize: "1.20rem",
                }}
              >
                コメントの投稿に失敗しました。再度お試しください。
              </Typography>
            )}
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ backgroundColor: "#9FD7EE", color: "#ffffff" }}
              >
                送信
              </Button>
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default CommentForm;
