import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, List, ListItem, Divider } from "@mui/material";
import { Comment } from "../types";

interface CommentListProps {
  comments: Comment[];
}

const CommentList: React.FC<CommentListProps> = ({ comments }) => {
  return (
    <Box sx={{ margin: "0 auto" }}>
      <List>
        {comments.map((comment, index) => (
          <React.Fragment key={comment.id}>
            <ListItem alignItems="flex-start">
              <Box>
                <Typography variant="body2" fontWeight="bold">
                  {comment.userName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {comment.createdAt.slice(0, 19).replace("T", " ")}
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 1 }}>
                  {comment.comment}
                </Typography>
              </Box>
            </ListItem>
            {index < comments.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default CommentList;
